






































import gql from 'graphql-tag';
import { Component, Watch } from 'vue-property-decorator';

import { Invoice, InvoiceType, TxnType } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import CompanyInvoicesCards from '@/components/company/CompanyInvoicesCards.vue';
import TransactionMatching from '@/components/transactions/TransactionMatching.vue';
import { MUT_SNACKBAR } from '@/store';

@Component({
  components: {
    CompanyInvoicesCards,
    TransactionMatching,
  },
  apollo: {
    invoices: {
      query: gql`
        query GetInvoices($orgId: ID!) {
          invoices(orgId: $orgId, includeDisabled: true) {
            id
            type
            status
            title
            totalAmount
            dueAmount
            currency
            exchangeRate
            url
            hasMatchedTransactions
            dueDate
            contact {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoadingInvoices',
    },
  },
})
export default class Matching extends BaseVue {
  public invoices: Invoice[] = [];

  public selectedInvoice: Invoice | null = null;
  public selectedTxBox: string | null = null;
  public selectedTxType: TxnType | null = null;
  public pendingMatches: Record<string, Invoice> = {};

  public isLoadingInvoices = 0;

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this.selectedInvoice = null;
    this.selectedTxBox = null;
    this.selectedTxType = null;
    this.pendingMatches = {};
  }

  public invoiceSelection(invoice: Invoice) {
    this.selectedInvoice = invoice;
    this.match();
  }

  public matchBoxClicked(txnId: string | null, txnType: TxnType) {
    this.selectedTxBox = txnId;
    this.selectedTxType = txnType;
    this.match();
  }

  public editMatchClicked(txnId: string, invoiceId: string) {
    const invoice = this.invoices.find((i) => i.id === invoiceId);
    if (invoice) {
      this.pendingMatches[txnId] = invoice;
      this.pendingMatches = { ...this.pendingMatches };
    } else {
      this._showSnackbar('error', 'Invoice could not be found');
    }
  }

  public match() {
    if (!this.selectedInvoice || !this.selectedTxBox) return;

    if (this.selectedTxType === TxnType.Receive && this.selectedInvoice.type === InvoiceType.Paying) {
      this._showSnackbar('error', 'Bills cannot be matched to Inflow transactions');
      return;
    } else if (this.selectedTxType === TxnType.Send && this.selectedInvoice.type === InvoiceType.Receiving) {
      this._showSnackbar('error', 'Invoices cannot be matched to Outflow transactions');
      return;
    }

    if (Object.keys(this.pendingMatches).some((key) => this.pendingMatches[key].id === this.selectedInvoice?.id)) {
      this._showSnackbar('warning', 'Invoice is being matched to another transactions');
      return;
    }

    this.pendingMatches[this.selectedTxBox] = this.selectedInvoice;
    this.pendingMatches = { ...this.pendingMatches };
  }

  public matchCompleted(txnId: string) {
    delete this.pendingMatches[txnId];
    this.pendingMatches = { ...this.pendingMatches };
  }

  private _showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }
}
