















































































































































































































import axios, { AxiosError, AxiosResponse } from 'axios';
import gql from 'graphql-tag';
import { isNumber } from 'lodash';
import moment from 'moment';
import { Component, Prop, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Category,
  Connection,
  ConnectionCategory,
  ConnectionStatus,
  Contact,
  ExchangeRateObject,
  Invoice,
  NetworkContactInput,
  Org,
  Providers,
  TransactionLite,
  TransactionsResultLite,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTabs from '@/components/ui/UiTabs.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { TransactionCountQuery, WalletsQuery } from '@/queries/transactionsPageQuery';
import { isTxnClosed } from '@/services/transactionServices';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { stringifyParams } from '@/utils/endpointUrlUtil';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';
import { TransactionsApi } from '../../../generated/api-svc';
import InvoiceCard from '../company/InvoiceCard.vue';
import InvoiceMatchingForm from './InvoiceMatchingForm.vue';
import TransactionCard from './TransactionCard.vue';

@Component({
  components: {
    TransactionCard,
    UiTextEdit,
    UiSelect,
    UiSelect2,
    UiDatePicker2,
    UiTabs,
    UiLoading,
    InvoiceCard,
    InvoiceMatchingForm,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingWallets',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    transactionCounts: {
      query: TransactionCountQuery,
      variables() {
        const ignore = this.vars.transactionFilter.ignoreFilter;
        let walletIds =
          this.vars.transactionFilter.walletFilter?.[0] !== 'All'
            ? this.vars.transactionFilter.walletFilter
            : undefined;
        if (walletIds?.length > 10) {
          walletIds = walletIds.slice(0, 10);
        }
        if (this.$store.state.currentOrg) {
          return {
            pivotDate: this.pivotDateVar,
            orgId: this.$store.state.currentOrg.id,
            walletIds,
            ignore,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingCounts',
      notifyOnNetworkStatusChange: true,
      deep: true,
      fetchPolicy: 'no-cache',
    },
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoadingConnections',
      update(data: { connections?: Connection[] }) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
})
export default class TransactionMatching extends BaseVue {
  @Prop({ default: {} })
  public pendingMatches!: Record<string, Invoice>;

  @Prop({ default: () => [] })
  public invoices!: Invoice[];

  @Prop()
  public isLoadingInvoices?: number;

  public selectedTxBox: string | null = null;

  declare connections: Connection[]; // populated via apollo
  declare transactionCounts?: any; // populated via apollo
  public transactionsLite: TransactionsResultLite = {
    txns: [],
    coins: [],
  }; // populated via restEndpoint

  public txnInvoices: Record<string, Invoice[]> = {};

  public searchToken = '';
  public hasExpandedFilters = true;
  public isLoadingWallets = 0;
  public isLoadingCounts = 0;
  public isLoadingTransactions = 0;
  public isLoadingExchangeRates = 0;
  public hasTransactionLoadError = false;

  public vars = {
    transactionFilter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletFilter: ['All'],
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public coinLookup = new Map<string, string>();
  public wallets: Wallet[] = [];
  public countTab: string | null = null;
  public apiBaseUrl = process.env.VUE_APP_API_URL;

  private _previousPromise: CancelablePromise<any> | null = null;
  private _currentRateCallIndex = 0;
  private _batchSize = 5;
  private _batchToken = Date.now();

  @Watch('countTab')
  public onCountTabChanged() {
    if (!this.countTab) return;
    if (this.countTab === 'categorize') {
      this.vars.transactionFilter.categorizationFilter = 'Uncategorized';
      this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    } else if (this.countTab === 'reconcile') {
      this.vars.transactionFilter.categorizationFilter = 'Categorized';
      this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    } else if (this.countTab === 'all') {
      this.vars.transactionFilter.categorizationFilter = 'All';
      this.vars.transactionFilter.reconciliationFilter = 'All';
    }
  }

  @Watch('transactionsLite')
  public updateCoinLookup() {
    this.coinLookup = this.transactionsLite
      ? new Map(this.transactionsLite?.coins?.map((c: any) => [c.currencyId, c.ticker]))
      : new Map();

    this._mapInvoicesToTxns();
  }

  @Watch('invoices')
  public invoicesChange() {
    this._mapInvoicesToTxns();
  }

  private searchDebounceTimer?: number;
  @Watch('searchToken')
  public onSearchTokenChange() {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.searchDebounceTimer = window.setTimeout(() => {
      this.searchDebounceTimer = undefined;
      // prevent reassignment if value has not changed, reassignment means another endpoint call
      if (this.searchToken !== this.vars.transactionFilter.searchTokens?.join(' ')) {
        this.vars.transactionFilter.searchTokens =
          this.searchToken.trim() !== '' ? this.searchToken.split(' ') : undefined;
      }
    }, 1000);
  }

  @Watch('vars')
  @Watch('vars.limit')
  @Watch('vars.transactionFilter.categorizationFilter')
  @Watch('vars.transactionFilter.reconciliationFilter')
  @Watch('vars.transactionFilter.ignoreFilter')
  @Watch('vars.transactionFilter.walletFilter')
  @Watch('vars.transactionFilter.searchTokens')
  @Watch('vars.transactionFilter.errored')
  @Watch('vars.transactionFilter.pivotDate')
  @Watch('vars.paginationToken')
  async filtersUpdated() {
    await this.callRestEndpoint();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this.resetFilters();
  }

  @Watch('pendingMatches')
  pendingMatchesChange() {
    if (this.selectedTxBox && this.pendingMatches[this.selectedTxBox]) {
      const txn = this.transactionsLite.txns.find((txn) => txn?.id === this.selectedTxBox);
      if (txn) this.matchBoxClicked(txn);
    }
  }

  get preferredTimezone() {
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.$store.state.currentOrg.timezone : moment.tz.guess();
  }

  async mounted() {
    this.callRestEndpoint();
    await this.$nextTick();
  }

  public get countTabs() {
    return [
      {
        label: 'Needs Categorization',
        value: 'categorize',
        count: this.transactionCounts.uncategorized,
      },
      {
        label: 'To Be Reconciled',
        value: 'reconcile',
        count: this.transactionCounts.unreconciled,
      },
      {
        label: 'All',
        value: 'all',
        count: this.transactionCounts.all,
      },
    ];
  }

  public get walletItems() {
    return [{ id: 'All', name: 'All Wallets' }, ...this.wallets.map((w) => ({ id: w.id, name: w.name }))];
  }

  public get categories(): Category[] {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public get feeContacts(): NetworkContactInput[] {
    return this.$store.state.currentOrg.accountingConfig?.networkContactIds;
  }

  public get displayTxns() {
    return this.hasTransactionLoadError ? [] : (this.transactionsLite?.txns ?? []).filter(isDefined);
  }

  public get connectionList(): Connection[] {
    const categories = this.categories;
    const contacts = this.contacts;

    let connections = this.connections ?? [];

    if (
      (contacts?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) ||
      (categories?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false)
    ) {
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      connections = connections.concat(manualAccountingConnection);
    }

    if (connections && connections.length > 0) {
      return connections;
    } else {
      return [];
    }
  }

  public get currentFiat() {
    const currency = this.$store.state.currentOrg.baseCurrency ?? 'USDa';
    const symbol =
      (this.$store.getters['fiats/FIATS'] as { name: string; symbol: string }[])?.find((x) => x.name === currency)
        ?.symbol ?? '$';
    return { currency, symbol };
  }

  public getTxnTickers(txn: TransactionLite) {
    return txn.txnLines
      ?.map((line) => this.coinLookup.get(line.assetId ?? ''))
      .filter((ticker, i, ar) => !!ticker && ar.indexOf(ticker) === i);
  }

  public getForexCategories(invoice: Invoice) {
    if (!this.categories) return [];

    const connectionId = this.contacts.find((c) => c.id === invoice.contact?.id)?.accountingConnectionId;
    const syncCheck = (category?: Category, connection?: Connection) => {
      if (!connection || connection.provider !== Providers.NetSuite) {
        return true;
      }
      if (!category?.lastUpdatedSEC || !connection?.lastSyncSEC) return true;
      return Math.abs(category.lastUpdatedSEC - connection.lastSyncSEC) / (1000 * 60) <= 20; // 20 minutes
    };

    if (connectionId) {
      return this.categories
        .filter(
          (c) =>
            c.accountingConnectionId === connectionId &&
            syncCheck(
              c,
              this.connections.find((x) => x.id === connectionId)
            )
        )
        .sort((a, b) => {
          //  we will try to sort by the account code
          if (isNumber(a.code) && isNumber(b.code)) {
            return Number(a.code) - Number(b.code);
          }

          //  default is a string comparison on name
          return a.name.localeCompare(b.name);
        });
    }

    return this.categories;
  }

  public getConnection(invoice: Invoice) {
    const connectionId = this.contacts.find((c) => c.id === invoice.contact?.id)?.accountingConnectionId;
    if (!connectionId) return undefined;

    return this.connections.find((c) => c.id === connectionId);
  }

  public onEarlier() {
    this.vars.paginationToken = this.transactionsLite
      ? this.transactionsLite.olderPageToken || this.vars.paginationToken
      : undefined;
  }

  public onLater() {
    this.vars.paginationToken = this.transactionsLite
      ? this.transactionsLite.newerPageToken || this.vars.paginationToken
      : undefined;
  }

  public onWalletsChange(wallets: string[]) {
    // if wallets is empty, set to all
    // also set to all if all is the last selection
    // but if all is selected and another wallet is selected last, remove all
    if (wallets.length === 0) {
      this.vars.transactionFilter.walletFilter = ['All'];
    } else if (wallets[wallets.length - 1] === 'All') {
      this.vars.transactionFilter.walletFilter = ['All'];
    } else if (wallets.includes('All')) {
      this.vars.transactionFilter.walletFilter = wallets.filter((w) => w !== 'All');
    } else {
      this.vars.transactionFilter.walletFilter = wallets;
    }
  }

  public onDateChange(date: string) {
    if (this.isValidDate(date)) {
      this.vars.transactionFilter.pivotDate = date;
      this.refresh();
    }
  }

  isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);
    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  resetFilters() {
    this.countTab = null;
    this.vars.transactionFilter.categorizationFilter = 'All';
    this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    this.vars.transactionFilter.ignoreFilter = 'Unignored';
    this.vars.transactionFilter.walletFilter = ['All'];
    this.vars.transactionFilter.searchTokens = undefined;
    this.vars.transactionFilter.errored = undefined;
    this.searchToken = '';
  }

  public async refresh() {
    await Promise.all([
      this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id),
      this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id),
      this.$apollo.queries.transactionCounts.refetch(),
      this.callRestEndpoint(),
    ]);
  }

  public async callRestEndpoint() {
    const orgId = this.$store.state.currentOrg;
    if (!orgId) return;

    this.isLoadingTransactions++;

    try {
      const params = {
        ...this.vars.transactionFilter,
        paginationToken: this.vars.paginationToken,
        pageLimit: Number(this.vars.limit),
        timezone: this.preferredTimezone,
      };

      if (params.walletFilter.length > 10) {
        params.walletFilter = params.walletFilter.slice(0, 10);
      }

      const endpointUrl = `${this.apiBaseUrl}orgs/${this.$store.state.currentOrg.id}/transactions?`;
      const queryParams = stringifyParams(params, { indices: false });

      const onResult = (data: TransactionsResultLite) => {
        this._currentRateCallIndex = 0;
        const tApi = new TransactionsApi(undefined, baConfig.getFriendlyApiUrl());
        const txns = data?.txns as TransactionLite[];
        this.isLoadingExchangeRates = 1;
        const newToken = Date.now();
        this._batchToken = newToken;
        const dirtyTxns = txns.filter((t) => !t.exchangeRates || t.exchangeRates.exchangeRatesDirty);

        if (dirtyTxns.length === 0) {
          this.isLoadingExchangeRates = 0;
        } else {
          this._processNextBatch(dirtyTxns, tApi, newToken).then(() => {
            this.isLoadingExchangeRates = 0;
          });
        }
      };

      if (this._previousPromise) {
        this._previousPromise.cancel();
      }

      const originalPromise = axios.get(endpointUrl + queryParams, {
        withCredentials: true,
      });
      const cancelablePromise = makeCancelable(originalPromise);
      this._previousPromise = cancelablePromise;
      const response = await cancelablePromise;

      if (response.status === 200) {
        this.transactionsLite = response.data;
        onResult(response.data);
      }

      this.isLoadingTransactions--;
    } catch (error) {
      this.isLoadingTransactions--;
      if (error instanceof CanceledError) {
        console.log('The promise was canceled');
      } else {
        console.log('Some other error', error);
        this.showErrorSnackbar('Error Fetching Transactions ' + error);
        this.hasTransactionLoadError = true;
      }
    }
  }

  private async _processNextBatch(txns: TransactionLite[], tApi: TransactionsApi, token: number): Promise<unknown> {
    if (token !== this._batchToken) {
      return Promise.resolve('Process aborted due to new query');
    }

    const batch = txns.slice(this._currentRateCallIndex, this._currentRateCallIndex + this._batchSize);
    const org = this.$store.state.currentOrg as Org | undefined;
    if (!org) {
      throw new Error('No current organization');
    }
    const responses = await Promise.allSettled(
      batch
        .filter((t) => !isTxnClosed(t, org))
        .map((t) => {
          return tApi.priceTransactionSec(this.$store.state.currentOrg.id, t.id || '', {
            withCredentials: true,
          });
        })
    );

    for (const res of responses) {
      switch (res.status) {
        case 'fulfilled': {
          const resVal = res.value;
          if (resVal.data.txn) {
            const existingTxn = (this.transactionsLite?.txns as TransactionLite[])?.find(
              (t: TransactionLite) => t.id === resVal.data.txn.id
            ) as TransactionLite;
            if (existingTxn) {
              existingTxn.exchangeRates = resVal.data.txn.exchangeRates as ExchangeRateObject;
              (existingTxn as any).typeGuess = resVal.data.txn.typeGuess;
            }
          }
          break;
        }
        case 'rejected': {
          if (res.reason.isAxiosError && res.reason.response?.data?.txn) {
            const axiosErr = res.reason as AxiosError;
            const errResp = axiosErr.response as AxiosResponse;
            const existingTxn = (this.transactionsLite?.txns as TransactionLite[])?.find(
              (t: TransactionLite) => t.id === errResp.data.txn.id
            ) as TransactionLite;
            if (existingTxn) {
              if (errResp.status === 409) {
                existingTxn.exchangeRates = {
                  exchangeRatesDirty: true,
                  exchangeRatesError: [`Too soon to price transaction, retry after ${errResp.data.retryAfter}`],
                };
              } else {
                existingTxn.exchangeRates = {
                  exchangeRatesDirty: true,
                  exchangeRatesError: [`Error pricing transaction ${errResp.status}-${errResp.statusText}`],
                };
              }
            }
          }
          break;
        }
      }
    }

    this._currentRateCallIndex += this._batchSize;
    if (this._currentRateCallIndex < txns.length) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      if (token !== this._batchToken) {
        return 'Process aborted due to new query';
      }
      return this._processNextBatch(txns, tApi, token);
    } else {
      return 'All rate batches processed';
    }
  }

  public canBeMatched(txn: TransactionLite) {
    if (!txn.type || ![TxnType.Receive, TxnType.Send].includes(txn.type)) {
      return {
        status: false,
        reason: 'type',
      };
    } else if (txn.categorizationStatus === CategorizationStatus.Categorized) {
      return {
        status: false,
        reason: 'categorized',
      };
    }

    return {
      status: true,
    };
  }

  public matchBoxClicked(txn: TransactionLite) {
    if (!this.canBeMatched(txn).status) return;

    const txnId = txn.id;
    this.selectedTxBox = !txnId || txnId === this.selectedTxBox ? null : txnId;
    this.$emit('boxClicked', this.selectedTxBox, txn.type);
  }

  public editMatch(txnId?: string | null, invoiceId?: string | null) {
    if (!txnId || !invoiceId) return;
    this.$emit('editMatch', txnId, invoiceId);
  }

  public getTxAccountingInvoices(txn: TransactionLite) {
    const [accountingDetails] = txn.accountingDetails ?? [];
    if (accountingDetails?.invoice) return accountingDetails.invoice.invoices;
    return [];
  }

  public matchSaved(txnId: string) {
    this.refresh();
    this.$emit('matchCompleted', txnId);
  }

  private _mapInvoicesToTxns() {
    for (const txn of this.transactionsLite.txns) {
      if (!txn?.id) continue;

      const invoices = this.getTxAccountingInvoices(txn);

      if (!invoices?.length) continue;

      const invoiceIds = invoices.map((i) => i?.invoiceId);
      this.txnInvoices[txn.id] = this.invoices.filter((i) => invoiceIds.includes(i.id));
    }

    this.txnInvoices = { ...this.txnInvoices };
  }
}
