
























































































import moment from 'moment';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Category, Invoice, InvoicePaymentLine } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { MUT_SNACKBAR } from '@/store';
import { getSymbolForCoin, getSymbolForCurrency } from '@/utils/coinUtils';

type FiatCurrency = { name: string; symbol: string };
type PartialInvoice = Pick<
  Invoice,
  | 'id'
  | 'type'
  | 'status'
  | 'title'
  | 'totalAmount'
  | 'dueAmount'
  | 'url'
  | 'hasMatchedTransactions'
  | 'dueDate'
  | 'contact'
  | 'currency'
>;

@Component({
  components: {
    UiTooltip,
  },
})
export default class InvoiceCard extends BaseVue {
  @Prop({ required: true })
  invoice!: PartialInvoice;

  @Prop({ default: false })
  isSelected!: boolean;

  @Prop({ default: null })
  payment!: InvoicePaymentLine;

  @Prop({ default: () => [] })
  readonly categories!: Category[];

  public symbolForCoin(coin?: string | null) {
    if (!coin) return '';
    const symbol = getSymbolForCurrency(coin);
    if (symbol === '??') return getSymbolForCoin(coin);
    return symbol;
  }

  public get currentFiat(): FiatCurrency {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USDa';
    return (
      this.$store.getters['fiats/FIATS']?.find((fiat: FiatCurrency) => fiat.name === baseCurrency) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  public get forexCategory() {
    const catId = this.payment.forex?.categoryId;

    if (catId) {
      const cat = this.categories.find((c) => c.id === catId);
      return cat ? cat.name : '';
    }

    return '';
  }

  public getDate(dateString: string | null): string {
    if (!dateString) return '';

    const m = moment(dateString).tz(this.$store.state.currentOrg.timezone);
    return m.format('MM/DD/yyyy');
  }

  public onInvoiceClick() {
    if (this.invoice.status === 'Paid') {
      this._showSnackbar('warning', 'Paid invoices cannot be matched to transactions');
      return;
    } else if (this.invoice.hasMatchedTransactions) {
      this._showSnackbar('warning', 'Invoice is already matched to a transaction');
      return;
    }

    this.$emit('invoiceClicked', this.invoice);
  }

  private _showSnackbar(action: string, message: string) {
    this.$store.commit(MUT_SNACKBAR, {
      color: action,
      message,
    });
  }
}
