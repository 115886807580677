









































































import gql from 'graphql-tag';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Invoice } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiDatePreset from '@/components/ui/UiDatePreset.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';

import InvoiceCard from './InvoiceCard.vue';

type PartialInvoice = Pick<
  Invoice,
  | 'id'
  | 'type'
  | 'status'
  | 'title'
  | 'totalAmount'
  | 'dueAmount'
  | 'url'
  | 'hasMatchedTransactions'
  | 'dueDate'
  | 'contact'
>;

@Component({
  components: {
    UiTextEdit,
    UiDatePicker2,
    UiDatePreset,
    UiLoading,
    InvoiceCard,
  },
  apollo: {
    invoices: {
      query: gql`
        query GetInvoices($orgId: ID!) {
          invoices(orgId: $orgId, includeDisabled: true) {
            id
            type
            status
            title
            totalAmount
            dueAmount
            currency
            exchangeRate
            url
            hasMatchedTransactions
            dueDate
            contact {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      // update: (data) => {
      //   const filtered = data.invoices.filter((m: Invoice) => m.type === 'Receiving');
      //   return filtered;
      // },
      loadingKey: 'isLoading',
    },
  },
})
export default class CompanyInvoicesCards extends BaseVue {
  public invoices: PartialInvoice[] = [];
  private _filteredInvoices: PartialInvoice[] = [];
  public visibleInvoices: PartialInvoice[] = [];
  public selectedInvoiceId: string | null = null;
  public isLoading = 0;
  public hasExpandedFilters = true;

  public searchInput = '';
  public filters = {
    startDate: '',
    endDate: '',
  };

  public hasMoreRows = true;
  public pagination = {
    page: 1,
    limit: 100,
  };

  @Watch('invoices')
  onInvoicesChange() {
    this._filterInvoices();
  }

  @Watch('searchInput')
  onSearch() {
    this._filterInvoices();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this._resetData();
  }

  async mounted() {
    await this.$nextTick();
  }

  public selectInvoice(invoice: PartialInvoice) {
    if (this.selectedInvoiceId === invoice.id) {
      this.$emit('selectionChange', null);
      this.selectedInvoiceId = null;
    } else {
      this.$emit('selectionChange', invoice);
      this.selectedInvoiceId = invoice.id;
    }
  }

  public handlePage(initialPage?: boolean) {
    const exit = (chunk: PartialInvoice[]) => {
      this.visibleInvoices.push(...chunk);
      this.hasMoreRows = this.visibleInvoices.length < this._filteredInvoices.length;
    };

    if (initialPage) {
      this.visibleInvoices = [];
      return exit(this._filteredInvoices.slice(0, this.pagination.limit));
    }

    const startAt = this.pagination.page++ * this.pagination.limit;
    const endAt = startAt + this.pagination.limit;
    const chunk = this._filteredInvoices.slice(startAt, endAt);
    return exit(chunk);
  }

  public onDatePresetChange(dates: string[]) {
    const [startDate, endDate] = dates;
    this.filters.startDate = startDate;
    this.filters.endDate = endDate;

    this._filterInvoices();
  }

  public onDateChange(field: 'startDate' | 'endDate', date: string | null) {
    if (date === null || this._isValidDate(date)) {
      this.filters[field] = date ?? '';
    }

    this._filterInvoices();
  }

  private _isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);
    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  private _filterInvoices() {
    this.pagination.page = 1;
    this.$emit('selectionChange', null);
    this.selectedInvoiceId = null;

    this._filteredInvoices = this.invoices.filter((inv) => {
      return this._matchesSearch(inv) && this._matchesDates(inv);
    });

    this.handlePage(true);
  }

  private _matchesSearch(inv: PartialInvoice) {
    const searchString = this.searchInput.toLowerCase();
    const title = inv?.title?.toLowerCase();
    const contactName = inv.contact?.name?.toLowerCase();

    return title?.includes(searchString) || contactName?.includes(searchString);
  }

  private _matchesDates(inv: PartialInvoice) {
    if (!inv.dueDate) return false;

    const dueDate = moment(inv.dueDate).tz(this.$store.state.currentOrg.timezone);
    const startDate = this.filters.startDate?.length ? moment(this.filters.startDate) : moment(0);
    const endDate = this.filters.endDate?.length ? moment(this.filters.endDate) : moment().add(1, 'day');

    return (
      dueDate.isAfter(startDate.tz(this.$store.state.currentOrg.timezone)) &&
      dueDate.isBefore(endDate.tz(this.$store.state.currentOrg.timezone))
    );
  }

  private _resetData() {
    this.invoices = [];
    this.filters.startDate = '';
    this.filters.endDate = '';
    this.searchInput = '';
    this.pagination.page = 1;
    this.$emit('selectionChange', null);
    this.selectedInvoiceId = null;
  }
}
