













































































import gql from 'graphql-tag';
import * as math from 'mathjs';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import {
  Category,
  Connection,
  Invoice,
  InvoiceInputType,
  InvoicePaymentLine,
  InvoicePaymentLineInput,
  InvoiceType,
  TransactionLite,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { getSymbolForCoin, getSymbolForCurrency } from '@/utils/coinUtils';
import { assertDefined } from '@/utils/guards';

import { calculateForex } from './categorization/invoiceCategorizationUtilities';

@Component({
  components: {
    UiTextEdit,
    UiSelect2,
    UiLoading,
  },
})
export default class InvoiceMatchingForm extends BaseVue {
  @Prop({ required: true })
  readonly invoice!: Invoice;

  @Prop({ required: true })
  readonly transaction!: TransactionLite;

  @Prop({ default: [] })
  readonly tickers!: string[];

  @Prop({ default: [] })
  readonly categories!: Category[];

  @Prop()
  readonly connection?: Connection;

  public paymentFormData: InvoicePaymentLineInput = {
    invoiceId: null,
    amountPaid: null,
    ticker: '',
    contactId: '',
    sourceTicker: '',
    sourceAmount: null,
    forex: {
      categoryId: '',
      fiat: this.$store.state.currentOrg.baseCurrency,
      amount: null,
    },
    walletId: null,
    walletDisabled: true,
  };

  public expectedAmount: string | null = null;
  public exchangeRates?: any[];
  public isSaving = false;

  mounted() {
    const [accountingDetails] = this.transaction.accountingDetails ?? [];
    if (accountingDetails?.invoice) {
      const paymentLine = accountingDetails.invoice.invoices.find((l) => l?.invoiceId === this.invoice.id);
      if (paymentLine) this._populateFromPaymentLine(paymentLine);
      else console.error('Invalid matching form tx/invoice combination');
    } else {
      this._populateFromCompiledData();
    }
  }

  public get invoiceCurrency() {
    if (!this.invoice.currency) return '';
    const symbol = getSymbolForCurrency(this.invoice.currency);
    if (symbol === '??') return getSymbolForCoin(this.invoice.currency);
    return symbol;
  }

  public get baseCurrency() {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency;
    return getSymbolForCurrency(baseCurrency);
  }

  public get invoiceForexAmount() {
    const { sourceAmount, sourceTicker, amountPaid } = this.paymentFormData;

    if (!sourceAmount || !sourceTicker || !amountPaid) {
      return '0';
    }

    const rates = this.transaction.accountingDetails?.[0]?.exchangeRates?.length
      ? this.transaction.accountingDetails?.[0]?.exchangeRates
      : this.transaction.exchangeRates?.exchangeRates;

    this.exchangeRates = rates ?? [];

    const rate = rates?.find((x) => x?.coin === sourceTicker)?.rate;
    const historicalExchangeRate = this.invoice.exchangeRate;

    const forex = calculateForex(sourceAmount, rate, amountPaid, historicalExchangeRate);
    return forex.toFixed(2).toString();
  }

  public get hasForexAmount() {
    return Number(this.invoiceForexAmount) !== 0;
  }

  public async save() {
    try {
      if (!this.paymentFormData.invoiceId || !this.paymentFormData.contactId) {
        this.showErrorSnackbar('Could not save! Invalid match!');
        return;
      }

      if (!this.paymentFormData.sourceAmount || this.paymentFormData.sourceAmount !== this.expectedAmount) {
        this.showErrorSnackbar(`Please enter a valid amount. Amount expected to be ${this.expectedAmount}.`);
        return;
      }

      if (!this.paymentFormData.amountPaid) {
        this.showErrorSnackbar(`Please enter a valid amount paid.`);
        return;
      }

      if (!this.paymentFormData.sourceTicker) {
        this.showErrorSnackbar(`Please select a token.`);
        return;
      }

      if (this.hasForexAmount && !this.paymentFormData.forex?.categoryId) {
        this.showErrorSnackbar(`Please select a forex category.`);
        return;
      }

      const paymentLine: InvoicePaymentLineInput = {
        ...this.paymentFormData,
      };

      if (!this.hasForexAmount) paymentLine.forex = null;
      else {
        paymentLine.forex!.amount = this.invoiceForexAmount;
      }

      const exchangeRates = this.exchangeRates?.map((r) => {
        return {
          coin: r.coin,
          fiat: r.fiat,
          priceId: r.priceId,
          rate: r.rate,
          unit: r.coinUnit,
        };
      });

      const transactionData = {
        accountingConnectionId: this.connection?.id,
        invoice: {
          exchangeRates,
          fees: undefined,
          invoices: [paymentLine],
          totalAmount: math.bignumber(paymentLine.sourceAmount).toFixed(2),
          type: this.invoice.type === InvoiceType.Receiving ? InvoiceInputType.Invoice : InvoiceInputType.Bill,
        },
      };

      this.isSaving = true;

      assertDefined(transactionData);
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        txnId: this.transaction.id,
        transactionData,
      };

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $txnId: ID!, $transactionData: TransactionData!) {
            categorizeTransaction(orgId: $orgId, txnId: $txnId, transactionData: $transactionData) {
              id
            }
          }
        `,
        // Parameters
        variables: vars,
      });

      if (resp?.data?.categorizeTransaction?.id) {
        this.showSuccessSnackbar('Match was saved successfully!');
        this.$emit('matchSaved');
      }

      this.isSaving = false;
    } catch (e) {
      console.error(e);
      this.showErrorSnackbar('Could not save match!');
      this.isSaving = false;
    }
  }

  private _populateFromCompiledData() {
    if (!this.paymentFormData.sourceTicker.length && !!this.tickers.length) {
      this.paymentFormData.sourceTicker = this.tickers[0];
    }

    const txnLine = this.transaction.txnLines?.filter((l) => l.operation !== 'FEE')[0];
    this.paymentFormData.sourceAmount = txnLine?.amount ?? null;
    this.expectedAmount = txnLine?.amount ?? null;
    this.paymentFormData.walletId = txnLine?.walletId ?? null;

    const patch: Partial<InvoicePaymentLineInput> = {
      invoiceId: this.invoice.id,
      amountPaid: this.invoice.dueAmount,
      ticker: this.invoice.currency ?? this.$store.state.currentOrg.baseCurrency,
      contactId: this.invoice.contact?.id || '',
    };

    this.paymentFormData = { ...this.paymentFormData, ...patch };
  }

  private _populateFromPaymentLine(paymentLine: InvoicePaymentLine) {
    const patch: InvoicePaymentLineInput = {
      invoiceId: paymentLine.invoiceId,
      amountPaid: paymentLine.amount.value,
      ticker: paymentLine.ticker,
      contactId: paymentLine.contactId,
      sourceTicker: paymentLine.coin ?? '',
      sourceAmount: paymentLine.coinAmount.value ?? null,
      walletId: paymentLine.walletId,
      walletDisabled: true,
      forex: {
        amount: paymentLine.forex?.amount.value || null,
        categoryId: paymentLine.forex?.categoryId || '',
        fiat: paymentLine.forex?.fiat || this.$store.state.currentOrg.baseCurrency,
      },
    };

    this.paymentFormData = { ...patch };
    this.expectedAmount = paymentLine.coinAmount.value ?? null;
  }
}
