























































































































































































































































import moment from 'moment';
import { Component, Prop, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Connection,
  Contact,
  NetworkContactInput,
  TransactionLite,
  TxnLineLite,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import numberUtils from '@/utils/numberUtils';

import AppLink from '../AppLink.vue';

interface TxnLineVM extends TxnLineLite {
  category: string;
  contact: string;
  feeCategory: string;
  feeContact: string;
  dirty: boolean;
}

interface TxnVM extends TransactionLite {
  txnLines: TxnLineVM[];
  canInline: boolean;
  useLines: boolean;
}

@Component({
  components: {
    UiTooltip,
    UiLoading,
    AppLink,
  },
})
export default class TransactionCard extends BaseVue {
  @Prop({ required: true })
  transaction!: TxnVM;

  @Prop({ required: true })
  connections!: Connection[];

  @Prop() contacts!: Contact[]; // all contacts, used for table and
  @Prop() feeContacts!: NetworkContactInput[]; // default fee contacts for
  @Prop() coinLookup!: Map<string, string>; // currency code to coin name
  @Prop() wallets!: Wallet[]; // all wallets, used for table and categorization
  @Prop() isLoadingExchangeRates!: number;
  @Prop({ default: () => ({ currency: 'USD', symbol: '$' }) })
  public readonly currentFiat!: { currency: string; symbol: string }; // current fiat currency

  public numFormat = numberUtils.format; // number formatter

  public get preferredTimezone() {
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.$store.state.currentOrg.timezone : moment.tz.guess();
  }

  public get txnLines() {
    const tx = this.transaction;

    tx.accountingConnectionId =
      tx.accountingConnectionId ?? (this.connections.find((cn) => cn.isDefault) || this.connections?.[0])?.id;
    const lines = this._getSortedTxnLines(tx);

    tx.txnLines.forEach((line) => {
      if (line?.amount?.startsWith('-')) {
        line.amount = line.amount?.slice(1); // remove minus sign to keep precision and not convert to number.
      }
      if (line?.feeAmount?.startsWith('-')) {
        line.feeAmount = line.feeAmount?.slice(1); // remove minus sign to keep precision and not convert to number.
      }
      if (line.feeAmount || line.feeAssetId) {
        this.knownFeeAddress(line, tx);
        // this.knownFeeCategory(y, x);
      }
      this.knownAddress(line, tx);
      // this.knownCategory(y, x);
    });

    return lines;
  }

  public get walletById() {
    return this.wallets.reduce((a, x) => {
      a[x.id as string] = x;
      return a;
    }, {} as Record<string, Wallet>);
  }

  public get walletIdByAddress() {
    return this.wallets.reduce((a, x) => {
      x.addresses?.forEach((y) => {
        y = y?.toLowerCase() ?? '';
        if (y && x.id) {
          if (!a[y]) {
            a[y] = [];
          }
          a[y?.toLowerCase()].push(x.id);
        }
      });
      return a;
    }, {} as Record<string, string[]>);
  }

  public knownAddress(line: any, txn: TxnVM) {
    const address = txn.type === TxnType.Receive ? line.from : line.to;
    const contactId = line.contact;
    const assetId = line.assetId;
    if (!contactId) {
      const catContactId = txn.accountingDetails?.[0]?.multivalue?.items?.find((x) =>
        x?.lines?.some(
          (y) =>
            y?.txnLineId === line.txnLineId &&
            y?.coin?.toLowerCase() === this.lookupCoin(assetId).toLowerCase() &&
            y?.coinAmount?.replace('-', '') === line.amount
        )
      )?.contactId;
      const defaultFeeContact =
        line.operation === 'FEE' && txn.canInline
          ? this.feeContacts.find((x) => x.blockchain.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase())
              ?.contactId ?? ''
          : '';
      const contact = this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) =>
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? x.addresses.some((y) => y.address === address) || defaultFeeContact === x.id
          : x.id === catContactId
      );

      if (contact) this.$set(line, 'contact', contact.id);
    }

    return line.contact;
  }

  public knownFeeAddress(line: any, txn: TxnVM) {
    const address = txn.type === TxnType.Receive ? line.from : line.to;
    const contactId = line.feeContact;
    const assetId = line.feeAssetId;
    if (!contactId) {
      const catContactId = txn.accountingDetails?.[0]?.multivalue?.items?.find((x) =>
        x?.lines?.some(
          (y) =>
            y?.txnLineId === line.txnLineId &&
            y?.coin?.toLowerCase() === this.lookupCoin(assetId).toLowerCase() &&
            y?.coinAmount?.replace('-', '') === line.feeAmount
        )
      )?.contactId;
      const defaultFeeContact = txn.canInline
        ? this.feeContacts.find((x) => x.blockchain.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase())
            ?.contactId ?? ''
        : '';
      const contact = this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) =>
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? x.addresses.some((y) => y.address === address && y.coin === this.lookupCoin(assetId)) ||
            defaultFeeContact === x.id
          : x.id === catContactId
      );

      if (contact) this.$set(line, 'feeContact', contact.id);
    }

    return line.feeContact;
  }

  public lookupCoin(currencyId: string) {
    return this.coinLookup.get(currencyId) ?? currencyId;
  }

  public getIconsForTxn(txn: TxnVM) {
    const icons = [] as { icon: string; class: string; text: string; position?: string }[];
    const iconDatas = {
      [TxnType.Receive]: {
        icon: 'fa-arrow-circle-left',
        class: 'tw-text-success-300 tw-transform tw--rotate-45',
        text: 'Received',
      },
      [TxnType.Send]: {
        icon: 'fa-arrow-circle-right',
        class: `tw-text-error-300 tw-transform tw--rotate-45`,
        text: 'Send',
      },
      [TxnType.Trade]: {
        icon: 'fa-exchange',
        class: 'tw-text-primary-300',
        text: 'Trade',
      },
      [TxnType.Transfer]: {
        icon: 'fa-refresh',
        class: 'tw-text-primary-300',
        text: 'Transfer',
      },
      [TxnType.ContractExecution]: {
        icon: 'fa-code',
        class: 'tw-text-primary-300',
        text: 'Contract execution',
      },
    } as Record<TxnType, typeof icons[number]>;
    if (txn.type) {
      if (txn.type in iconDatas) {
        icons.push(iconDatas[txn.type]);
      }
      if (txn.type === TxnType.Receive && txn.hasMatchedInvoices) {
        icons.push({
          icon: 'fa-credit-card-alt',
          class: 'tw-text-primary-300',
          text: 'Invoice payment',
        });
      }
    }
    if (txn.isCombined) {
      icons.push({
        icon: 'fa-compress',
        class: 'tw-text-primary-300',
        text: 'Combined transaction',
      });
    }
    if (txn.isCombinedSubTransaction) {
      icons.push({
        icon: 'fa-sign-in',
        class: 'tw-text-primary-300 tw-transform tw-rotate-180',
        text: 'Combined sub-transaction',
      });
    }
    if (txn.errored) {
      icons.push({
        icon: 'fa-exclamation-circle',
        class: 'tw-text-error-300',
        text: 'Errored', // txn.errors?.join('\n') ?? 'Errored',
      });
    }
    // if (!txn.useLines) {
    //   icons.push({
    //     icon: 'fa-exclamation-circle',
    //     class: 'tw-text-gray-300',
    //     text: 'This transaction cannot be inline categorized',
    //     position: 'right',
    //   });
    // }
    return icons;
  }

  public singleLink(txn: TxnVM) {
    const metadata = this.displayMetadata(txn.metadata);
    const viewLinks = txn.viewLinks;
    const txnUrl = metadata?.find((x: { key: string; value: string }) => x.key.toLowerCase() === 'txnurl')?.value;

    if (txnUrl) {
      return txnUrl;
    } else if (viewLinks && viewLinks.length === 1) {
      return viewLinks[0]?.link;
    } else {
      return undefined;
    }
  }

  public multiLink(txn: TxnVM) {
    const viewLinks = txn.viewLinks;
    if (viewLinks && viewLinks.length > 1) {
      return viewLinks;
    } else {
      return undefined;
    }
  }

  public displayMetadata(metadata: any[]) {
    return metadata?.reduce((a, x) => {
      for (const key of Object.keys(x)) {
        a.push({ key: key, value: x[key] });
      }
      return a;
    }, [] as { key: string; value: string }[]);
  }

  public splitDate(date?: number | null) {
    if (!date) return [];

    const formattedDate = moment.unix(date).tz(this.preferredTimezone).format('MM/DD/YY hh:mm a');
    const splitDate = formattedDate?.split(' ') || [];
    const dateParts = [];
    if (splitDate.length > 0) {
      dateParts.push(splitDate[0]);
      dateParts.push(splitDate[1] + ' ' + splitDate[2]);
    }

    return dateParts;
  }

  public getContactsByConnection(accountingConnectionId: string) {
    return this.contacts.filter((x) => x.accountingConnectionId === accountingConnectionId);
  }

  public getContactName(contactId: string) {
    const contact = this.contacts.find((x) => x.id === contactId);
    return contact?.name ?? '';
  }

  public getWalletNameByAddress(address: string, walletId: string) {
    return this.walletIdByAddress?.[address.toLowerCase()]?.some((y) => y === walletId)
      ? this.walletById?.[walletId]?.name
      : '';
  }

  public getFmv(amount: number, txn: TxnVM, coin: string) {
    const rates = txn.accountingDetails?.[0]?.exchangeRates?.length
      ? txn.accountingDetails?.[0]?.exchangeRates
      : txn.exchangeRates?.exchangeRates;
    const rate = rates?.find((x) => x?.coin === coin);
    return amount * parseFloat(rate?.rate || '0');
  }

  private _getSortedTxnLines(txn: TxnVM) {
    // sort the txn lines placing the ones with feeAmount or operation equals 'FEE' at the end also secondary sort by walletId, do not mutate the original array
    return (
      (txn.txnLines?.slice()?.sort((a, b) => {
        if ((a?.feeAmount && !b?.feeAmount) || (a?.operation === 'FEE' && b?.operation !== 'FEE')) {
          return 1;
        } else if ((!a?.feeAmount && b?.feeAmount) || (a?.operation !== 'FEE' && b?.operation === 'FEE')) {
          return -1;
        } else if ((a?.walletId || '') > (b?.walletId || '')) {
          return 1;
        } else if ((a?.walletId || '') < (b?.walletId || '')) {
          return -1;
        } else {
          return 0;
        }
      }) as any[]) ?? []
    );
  }
}
